import { FunctionComponent, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/styles/index.scss";

const AgeGate = lazy(() => import("./app/AgeGate"));
const PrlNpl = lazy(() => import("./app/PrlNpl"));

const urls = {
  ageGate: "/age-gate",
  prlNpl: "/prl-npl",
};

const App: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={urls.ageGate} element={<AgeGate />} />
      <Route path={urls.prlNpl} element={<PrlNpl />} />
    </Routes>
  );
};

export default App;
